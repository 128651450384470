@media (max-width: 479px) {
	.page-title {
		font-size: 2em;
		margin: 0 0 20px;
	}
	.header {
		&__logo {
			width: 140px;
			padding: 16px 0;
		}
		&__navigation {
			display: none;
		}
		&__navigation-mobile {
			margin-left: 10px;
			margin-top: 16px;
		}
		&__phone {
			display: block;
			float: none;
			clear: both;
			margin-left: 0px;
			margin-bottom: 10px;
			line-height: 1;
		}
		&__callback {
			display: none;
		}
	}
	.top-navigation {
		&__item {
			display: block;
			float: none;
		}
		&__link {
			display: block;
			float: none;
			padding: 10px 15px;
			margin: 0 6px;
			font-size: 0.875em;
			&:hover {
				padding: 10px 15px;
				border-bottom: none;
			}
		}
	}
	.section-main-photo {
		&__about {
			height: auto;
			top: 0;
			position: static;
		}
		&__about-content {
			height: auto;
			font-size: 0.85em;
			margin: 0 -15px;
			padding: 15px;
		}
		&__about-more {
			display: none;
		}
	}
	.section-main-services {
		padding: 20px 0;
		&__item {
			display: block;
			width: 100%;
			padding: 10px 10px 20px;
		}
		&__caption {
			margin-top: 10px;
		}
	}
	.section-main-portfolio {
		&__item {
			width: 100%;
		}
	}
	.section-main-description {
		&__text {
			padding: 20px;
		}
	}
	.section-map {
		&__layer-form {
			position: static;
			top: 0;
			left: 0;
			height: auto;
			border-bottom: 1px solid $gray;
		}
		&__container {
			position: static;
			height: auto;
		}
		&__row {
			position: static;
			height: auto;
		}
		&__form {
			margin: 0;
			padding: 20px;
		}
	}
	.footer {
		padding: 20px 0;
		text-align: center;
		&__navigation {
			display: none;
		}
		&__logo {
			margin-bottom: 20px;
		}
		&__contacts {
			width: 100%;
			float: none;
			margin-bottom: 10px;
			& p {
				margin: 0 0 5px;
			}
		}
		&__socials {
			width: 100%;
			text-align: center;
			float: none;
		}
		&__social-link {
			margin-left: 0;
			margin-right: 10px;
		}
		&__copy {
			position: static;
			left: 0;
			bottom: 0;
			padding: 15px;
		}
	}
	.news {
		&__item {
			height: auto;
			max-height: none;
			margin-bottom: 20px;
		}
		&__block-img {
			float: none;
			padding: 0;
			text-align: center;
			margin: 0 auto;
			height: auto;
		}
		&__block-info {
			padding: 20px;
			min-height: none;
		}
	}
	.section-contacts {
		&__item {
			height: auto;
			max-height: none;
			margin-bottom: 20px;
		}
		&__block-img {
			float: none;
			padding: 0;
			text-align: center;
			margin: 0 auto;
			height: auto;
		}
		&__block-info {
			padding: 20px;
			min-height: 0;
		}
	}
	.section-order {
		&__form {
			padding: 20px;
		}
		&__form-section {
			margin-bottom: 20px;
		}
		& .form-group {
			margin-bottom: 0;
		}
		& .form-control {
			margin-bottom: 10px;
		}
	}
	.section-main-news {
		padding-bottom: 20px;
	}
	.section-news {
		padding-bottom: 20px;
	}
	.section-projects {
		&__item {
			margin-bottom: 20px;
		}
		&__item-inner {
			display: block;
		}
		&__item-inner-tr {
			display: block;
		}
		&__info-block {
			position: static;
			display: block;
			width: 100%;
		}
		&__img-block {
			position: static;
			margin-left: 0;
			display: block;
			width: 100%;
		}
		&__item-hover  {
			display: none;
		}
	}
	.section-project {
		&__prev-next {
			display: none;
		}
		&__item {
			margin-bottom: 20px;
		}
		&__item-btn {
			font-size: 1.5em;
		}
		&__item-inner {
			padding: 20px;
		}
		&__video {
			margin-bottom: 20px;
		}
		&__gallery {
			margin-bottom: 20px;
		}
	}
	.section-services {
		&__card-inner {
			text-align: center;
		}
		&__card-icon {
			border-bottom: 1px solid $white-dirty;
			margin-right: 0;
			width: 100%;
			padding: 20px;
		}
		&__card-title {
			font-size: 1.125em;
			display: block;
			padding-top: 20px;
			padding-bottom: 0;
		}
		&__card-more-sign {
			display: block;
			float: none;
			padding: 0 0 10px;
		}
		&__card-arrow {
			font-size: 1.5em;
		}
		&__item {
			margin-bottom: 20px; 
		}
		&__card-content table tr th,
		&__card-content table tr td {
			padding: 2px;
		}
	}
	.section-page {
		padding: 20px 0;
	}
	.btn-default {
		font-size: 1.25em;
	}
	#map {
		height: 300px !important;
	}
	.slider-commands__item {
		margin: 0 20px;
	}
	.slider-commands__button-next {
		display: none;
	}
	.slider-commands__button-prev {
		display: none;
	}
}

@media (min-width: 480px) and (max-width: 767px) {
	.header {
		&__logo {
			width: 140px;
			padding: 16px 0;
		}
		&__navigation {
			display: none;
		}
		&__navigation-mobile {
			margin-left: 10px;
			margin-top: 16px;
		}
		&__phone {
			line-height: 61px;
			margin-right: 20px;
		}
		&__callback {
			display: none;
		}
	}
	.top-navigation {
		&__item {
			display: block;
			float: none;
		}
		&__link {
			display: block;
			float: none;
			padding: 10px 15px;
			margin: 0 6px;
			font-size: 0.875em;
			&:hover {
				padding: 10px 15px;
				border-bottom: none;
			}
		}
	}
	.section-main-photo {
		&__about {
			height: auto;
			top: 0;
			position: static;
		}
		&__about-content {
			height: auto;
			font-size: 0.85em;
			margin: 0 -15px;
			padding: 15px;
		}
		&__about-more {
			display: none;
		}
	}
	.section-main-services {
		padding: 20px 0;
		&__item {
			width: 50%;
			padding: 10px 10px 20px;
		}
		&__caption {
			margin-top: 10px;
		}
	}
	.section-main-portfolio {
		&__item {
			width: 100%;
		}
	}
	.section-map {
		&__layer-form {
			position: static;
			top: 0;
			left: 0;
			height: auto;
			border-bottom: 1px solid $gray;
		}
		&__container {
			position: static;
			height: auto;
		}
		&__row {
			position: static;
			height: auto;
		}
		&__form {
			margin: 0;
			padding: 20px;
		}
	}
	#map {
		height: 300px !important;
	}
	.footer {
		padding: 20px 0;
		text-align: center;
		&__navigation {
			display: none;
		}
		&__logo {
			margin-bottom: 20px;
		}
		&__contacts {
			width: 100%;
			float: none;
			margin-bottom: 10px;
			& p {
				margin: 0 0 5px;
			}
		}
		&__socials {
			width: 100%;
			text-align: center;
			float: none;
		}
		&__social-link {
			margin-left: 0;
			margin-right: 10px;
		}
		&__copy {
			position: static;
			left: 0;
			bottom: 0;
			padding: 15px;
		}
	}
	.section-main-description {
		&__text {
			padding: 20px;
		}
	}
	.section-order {
		&__form {
			padding: 20px;
		}
		&__form-section {
			margin-bottom: 20px;
		}
		& .form-group {
			margin-bottom: 0;
		}
		& .form-control {
			margin-bottom: 10px;
		}
	}
	.news {
		&__item {
			height: auto;
			max-height: none;
			margin-bottom: 20px;
		}
		&__block-img {
			//float: none;
			padding: 0;
			text-align: center;
			margin: 0 auto;
			height: auto;
			width: 200px;
		}
		&__block-info {
			padding: 20px 20px 20px 225px;
			min-height: 200px;
			&_opened {
				max-height: none;
				min-height: 245px;
			}
		}
		&__content {
			height: 60px;
			&_opened {
				height: auto;
			}
		}
	}
	.section-contacts {
		&__item {
			height: auto;
			max-height: none;
			margin-bottom: 20px;
		}
		&__block-img {
			padding: 0;
			text-align: center;
			margin: 0 auto;
			height: auto;
			width: 180px;
		}
		&__block-info {
			padding: 20px 20px 20px 225px;
			min-height: 246px;
		}
	}
	.section-projects {
		&__item {
			margin-bottom: 20px;
		}
		&__item-inner {
			display: block;
		}
		&__item-inner-tr {
			display: block;
		}
		&__info-block {
			position: static;
			display: block;
			width: 100%;
		}
		&__img-block {
			position: static;
			margin-left: 0;
			display: block;
			text-align: center;
			width: 100%;
			& .img-responsive {
				margin: 0 auto;
			}
		}
		&__item-hover  {
			display: none;
		}
	}
	.section-project {
		&__prev-next {
			display: none;
		}
		&__item {
			margin-bottom: 20px;
		}
		&__item-inner {
			padding: 20px;
		}
		&__video {
			margin-bottom: 20px;
		}
		&__gallery {
			margin-bottom: 20px;
		}
	}
	.section-services {
		&__card-inner {
			text-align: center;
		}
		&__card-icon {
			border-bottom: 1px solid $white-dirty;
			margin-right: 0;
			width: 100%;
			padding: 20px;
		}
		&__card-title {
			font-size: 1.125em;
			display: block;
			padding-top: 20px;
			padding-bottom: 0;
		}
		&__card-more-sign {
			display: block;
			float: none;
			padding: 0 0 10px;
		}
		&__card-arrow {
			font-size: 1.5em;
		}
		&__item {
			margin-bottom: 20px; 
		}
		&__card-content table tr th,
		&__card-content table tr td {
			padding: 10px;
		}
	}
	.slider-commands__button-next {
		display: none;
	}
	.slider-commands__button-prev {
		display: none;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.header {
		&__logo {
			width: 160px;
			padding: 22px 0;
		}
		&__navigation {
			padding: 0 20px;
		}
		&__phone {
			float: right;
			margin-left: 20px;//
			line-height: 85px;/**/
		}
		&__callback {
			margin-left: 15px;
			padding: 22px 0;
		}
		&__callback-link {

		}
	}
	.top-navigation-mobile {
		width: 100% !important;
		float: none !important;
	}
	.top-navigation {
		display: none;
		&__item {
			display: block !important;
			float: none !important;
		}
		&__link {
			display: block !important;
			float: none !important;
			padding: 10px 15px !important;
			margin: 0 6px !important;
			&:hover {
				padding: 10px 15px !important;
				border-bottom: none !important;
			}
		}
	}
	.section-main-portfolio {
		&__caption-title {
			font-size: 1em;
		}
	}
	.section-main-photo {
		&__about {
			height: 236px;
			top: -118px;
		}
		&__about-content {
			height: 150px;
			font-size: 0.85em;
		}
	}
	.section-map {
		&__layer-form {
			position: static;
			top: 0;
			left: 0;
			height: auto;
			border-bottom: 1px solid $gray;
		}
		&__container {
			position: static;
			height: auto;
		}
		&__row {
			position: static;
			height: auto;
		}
		&__form {
			margin: 0;
			padding: 20px;
		}
	}
	.section-services {
		&__card-icon {
			margin-right: 40px;
			padding: 30px 20px;
		}
		&__card-title {
			font-size: 1.625em;
			vertical-align: super;
		}
		&__card-more-sign {
			padding: 30px;
		}
	}
	.section-contacts {
		&__block-img {
			width: 230px;
		}
		&__block-info {
			min-height: 283px;
			padding: 40px 40px 40px 265px;
		}
	}
	.section-order {
		&__form {
			padding: 20px;
		}
		&__form-section {
			margin-bottom: 20px;
		}
		& .form-group {
			margin-bottom: 0;
		}
		& .form-control {
			margin-bottom: 10px;
		}
	}
	.footer {
		padding: 20px 0;
		text-align: center;
		&__navigation {
			display: none;
		}
		&__logo {
			margin-bottom: 20px;
		}
		&__contacts {
			width: 100%;
			float: none;
			margin-bottom: 10px;
			& p {
				margin: 0 0 5px;
			}
		}
		&__socials {
			width: 100%;
			text-align: center;
			float: none;
		}
		&__social-link {
			margin-left: 0;
			margin-right: 10px;
		}
		&__copy {
			position: static;
			left: 0;
			bottom: 0;
			padding: 15px;
		}
	}
	.section-project__prev-next {
		& .col-md-5 {
			width: 50%;
			float: left;
		}
	}
	.slider-commands__button-next {
		right: 0;
	}
	.slider-commands__button-prev {
		left: 0;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.header {
		&__logo {
			width: 160px;
			padding: 22px 0;
		}
		&__navigation {
			padding: 0 20px;
		}
		&__navigation-mobile {
			display: none;
		}
		&__phone {
			font-weight: bold;
			line-height: 85px;
		}
		&__callback {
			margin-left: 15px;
			padding: 22px 0;
		}
		&__callback-link {

		}
	}
	.top-navigation {
		&__link {
			padding: 34px 0 34px;
			margin: 0 6px;
			font-size: 0.875em;
			&:hover {
				padding: 34px 0 30px;
			}
		}
	}
	.section-main-portfolio {
		&__caption-title {
			font-size: 1.5em;
		}
	}
	.section-main-photo {
		&__about {
			height: 236px;
			top: -118px;
		}
		&__about-content {
			height: 150px;
			font-size: 0.85em;
		}
	}
	.section-map {
		&__form {
			padding-left: 30px;
			padding-right: 30px;
		}
		&__form-title {
			font-size: 1.125em;
		}
	}
	.section-services {
		&__card-icon {
			margin-right: 40px;
			padding: 30px 20px;
		}
		&__card-title {
			font-size: 1.625em;
			vertical-align: super;
		}
		&__card-more-sign {
			padding: 30px;
		}
	}

	.section-contacts {
		&__block-img {
			width: 230px;
		}
		&__block-info {
			min-height: 283px;
			padding: 40px 40px 40px 265px;
		}
	}
}
@media (min-width: 1200px) {
	.header {
		&__navigation-mobile {
			display: none;
		}
	}
}
/*
@media (min-width: 1280px) {
	
}
*/