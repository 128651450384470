@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.eot?6852221');
  src: url('../fonts/fontello.eot?6852221#iefix') format('embedded-opentype'),
       url('../fonts/fontello.woff?6852221') format('woff'),
       url('../fonts/fontello.ttf?6852221') format('truetype'),
       url('../fonts/fontello.svg?6852221#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: auto;
    margin: 0;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-angle-right:before { content: '\e800'; } /* '' */
.icon-instagram:before { content: '\e801'; } /* '' */
.icon-angle-left:before { content: '\e802'; } /* '' */
.icon-twitter:before { content: '\e803'; } /* '' */
.icon-gplus:before { content: '\e804'; } /* '' */
.icon-vkontakte:before { content: '\e805'; } /* '' */
.icon-angle-up:before { content: '\e806'; } /* '' */
.icon-angle-down:before { content: '\e807'; } /* '' */
.icon-dot:before { content: '\e808'; } /* '' */
.icon-eye:before { content: '\e809'; } /* '' */
.icon-audio:before { content: '\e80a'; } /* '' */
.icon-pin:before { content: '\e80b'; } /* '' */
.icon-outsourcing:before { content: '\e80c'; } /* '' */
.icon-mediabuying:before { content: '\e80d'; } /* '' */
.icon-campaign:before { content: '\e80e'; } /* '' */
.icon-smi:before { content: '\e80f'; } /* '' */
.icon-design:before { content: '\e810'; } /* '' */
.icon-production:before { content: '\e811'; } /* '' */
.icon-copywriting:before { content: '\e812'; } /* '' */
.icon-promo:before { content: '\e813'; } /* '' */
.icon-training:before { content: '\e814'; } /* '' */
.icon-phone:before { content: '\e815'; } /* '' */
.icon-mailme:before { content: '\e816'; } /* '' */
.icon-mail:before { content: '\e817'; } /* '' */
.icon {
    display: inline-block;
    vertical-align: middle;
}
.icon-pin {
	position: relative;
    &:before {
        font-size: 100px;
        line-height: 1;
        color: $red;
        margin: 0;
        width: auto;
		position: relative;
		z-index: 2;
    }
	&:after {
		content: "";
		display: block;
		width: 60px;
		height: 45px;
		background: $white;
		position: absolute;
		top: 20px;
		left: 12px;
		z-index: 1;
	}
}
