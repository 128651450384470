/*! jQuery UI - v1.11.4 - 2015-11-19
* http://jqueryui.com
* Includes: core.css, slider.css, theme.css
* To view and modify this theme, visit http://jqueryui.com/themeroller/?ffDefault=segoe%20ui%2CArial%2Csans-serif&fwDefault=bold&fsDefault=1.1em&cornerRadius=0&bgColorHeader=%23ece8da&bgTextureHeader=gloss_wave&bgImgOpacityHeader=100&borderColorHeader=%23d4ccb0&fcHeader=%23433f38&iconColorHeader=%23847e71&bgColorContent=%23c3c4c4&bgTextureContent=highlight_hard&bgImgOpacityContent=100&borderColorContent=%23c3c4c4&fcContent=%23312e25&iconColorContent=%23808080&bgColorDefault=%231ccca9&bgTextureDefault=highlight_hard&bgImgOpacityDefault=15&borderColorDefault=%231ccca9&fcDefault=%23ffffff&iconColorDefault=%23eeeeee&bgColorHover=%23f47724&bgTextureHover=highlight_soft&bgImgOpacityHover=25&borderColorHover=%23f47724&fcHover=%23ffffff&iconColorHover=%23ffffff&bgColorActive=%231fdeb8&bgTextureActive=highlight_hard&bgImgOpacityActive=100&borderColorActive=%231fdeb8&fcActive=%23459e00&iconColorActive=%238DC262&bgColorHighlight=%23fcf0ba&bgTextureHighlight=glass&bgImgOpacityHighlight=55&borderColorHighlight=%23e8e1b5&fcHighlight=%23363636&iconColorHighlight=%238DC262&bgColorError=%23ffedad&bgTextureError=highlight_soft&bgImgOpacityError=95&borderColorError=%23e3a345&fcError=%23cd5c0a&iconColorError=%23cd0a0a&bgColorOverlay=%232b2922&bgTextureOverlay=inset_soft&bgImgOpacityOverlay=15&opacityOverlay=90&bgColorShadow=%23cccccc&bgTextureShadow=highlight_hard&bgImgOpacityShadow=95&opacityShadow=20&thicknessShadow=12px&offsetTopShadow=-12px&offsetLeftShadow=-12px&cornerRadiusShadow=10px
* Copyright jQuery Foundation and other contributors; Licensed MIT */

/* Layout helpers
----------------------------------*/
.ui-helper-hidden {
    display: none;
}

.ui-helper-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.ui-helper-reset {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    line-height: 1.3;
    text-decoration: none;
    font-size: 100%;
    list-style: none;
}

.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
    content: "";
    display: table;
    border-collapse: collapse;
}

.ui-helper-clearfix:after {
    clear: both;
}

.ui-helper-clearfix {
    min-height: 0; /* support: IE7 */
}

.ui-helper-zfix {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0;
    filter: Alpha(Opacity=0); /* support: IE8 */
}

.ui-front {
    z-index: 100;
}

/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
    cursor: default !important;
}

/* Icons
----------------------------------*/

/* states and images */
.ui-icon {
    display: block;
    text-indent: -99999px;
    overflow: hidden;
    background-repeat: no-repeat;
}

/* Misc visuals
----------------------------------*/

/* Overlays */
.ui-widget-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.ui-slider {
    position: relative;
    text-align: left;
}

.ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: 0 1px 0 RGBA(0, 0, 0, .3);
    cursor: default;
    -ms-touch-action: none;
    touch-action: none;
}

.ui-slider .ui-slider-range {
    position: absolute;
    z-index: 1;
    font-size: .7em;
    display: block;
    border: 0;
    background-position: 0 0;
}

/* support: IE8 - See #6727 */
.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
    filter: inherit;
}

.ui-slider-horizontal {
    height: .8em;
}

.ui-slider-horizontal .ui-slider-handle {
    top: -6px;
    margin-left: -6px;
}

.ui-slider-horizontal .ui-slider-range {
    top: 0;
    height: 100%;
}

.ui-slider-horizontal .ui-slider-range-min {
    left: 0;
}

.ui-slider-horizontal .ui-slider-range-max {
    right: 0;
}

.ui-slider-vertical {
    width: .8em;
    height: 100px;
}

.ui-slider-vertical .ui-slider-handle {
    left: -.3em;
    margin-left: 0;
    margin-bottom: -.6em;
}

.ui-slider-vertical .ui-slider-range {
    left: 0;
    width: 100%;
}

.ui-slider-vertical .ui-slider-range-min {
    bottom: 0;
}

.ui-slider-vertical .ui-slider-range-max {
    top: 0;
}

/* Component containers
----------------------------------*/
.ui-widget {
    font-family: segoe ui, Arial, sans-serif;
    font-size: 8px;
}

.ui-widget .ui-widget {
    font-size: 1em;
}

.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
    font-family: segoe ui, Arial, sans-serif;
    font-size: 1em;
}

.ui-widget-content {
    border: 1px solid #c3c4c4;
    background: #c3c4c4;
    color: #312e25;
}

.ui-widget-content a {
    color: #312e25;
}

.ui-widget-header {
    border: 1px solid #d4ccb0;
    background: #f47724;
    color: #f47724;
    font-weight: bold;
}

.ui-widget-header a {
    color: #433f38;
}

/* Interaction states
----------------------------------*/
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
    background: #1ccca9;
    font-weight: bold;
    color: #ffffff;
    outline: none;
}

.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited {
    color: #ffffff;
    text-decoration: none;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover {
    background: #f47724;
    font-weight: bold;
    color: #ffffff;
}

.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited,
.ui-state-focus a,
.ui-state-focus a:hover,
.ui-state-focus a:link,
.ui-state-focus a:visited {
    color: #ffffff;
    text-decoration: none;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
    background: #1fdeb8;
    font-weight: bold;
    color: #459e00;
}

.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
    color: #459e00;
    text-decoration: none;
}

/* Interaction Cues
----------------------------------*/
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
    border: 1px solid #e8e1b5;
    background: #fcf0ba;
    color: #363636;
}

.ui-state-highlight a,
.ui-widget-content .ui-state-highlight a,
.ui-widget-header .ui-state-highlight a {
    color: #363636;
}

.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-widget-header .ui-state-error {
    border: 1px solid #e3a345;
    background: #ffedad;
    color: #cd5c0a;
}

.ui-state-error a,
.ui-widget-content .ui-state-error a,
.ui-widget-header .ui-state-error a {
    color: #cd5c0a;
}

.ui-state-error-text,
.ui-widget-content .ui-state-error-text,
.ui-widget-header .ui-state-error-text {
    color: #cd5c0a;
}

.ui-priority-primary,
.ui-widget-content .ui-priority-primary,
.ui-widget-header .ui-priority-primary {
    font-weight: bold;
}

.ui-priority-secondary,
.ui-widget-content .ui-priority-secondary,
.ui-widget-header .ui-priority-secondary {
    opacity: .7;
    filter: Alpha(Opacity=70); /* support: IE8 */
    font-weight: normal;
}

.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
    opacity: .35;
    filter: Alpha(Opacity=35); /* support: IE8 */
    background-image: none;
}

.ui-state-disabled .ui-icon {
    filter: Alpha(Opacity=35); /* support: IE8 - See #6059 */
}

/* Misc visuals
----------------------------------*/

/* Corner radius */
.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
    border-top-left-radius: 0;
}

.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
    border-top-right-radius: 0;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
    border-bottom-left-radius: 0;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
    border-bottom-right-radius: 0;
}

/* Overlays */
.ui-widget-overlay {
    background: #2b2922;
    opacity: .9;
    filter: Alpha(Opacity=90); /* support: IE8 */
}

.ui-widget-shadow {
    margin: -12px 0 0 -12px;
    padding: 12px;
    background: #cccccc;
    opacity: .2;
    filter: Alpha(Opacity=20); /* support: IE8 */
    border-radius: 10px;
}
