/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox, #cboxOverlay, #cboxWrapper {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9999;
	overflow: hidden;
}

#cboxWrapper {
	max-width: none;
}

#cboxOverlay {
	position: fixed;
	width: 100%;
	height: 100%;
}

#cboxMiddleLeft, #cboxBottomLeft {
	clear: left;
}

#cboxContent {
	position: relative;
}

#cboxLoadedContent {
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

#cboxTitle {
	margin: 0;
}

#cboxLoadingOverlay, #cboxLoadingGraphic {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow {
	cursor: pointer;
}

.cboxPhoto {
	float: left;
	margin: auto;
	border: 0;
	display: block;
	max-width: none;
	-ms-interpolation-mode: bicubic;
}

.cboxIframe {
	width: 100%;
	height: 100%;
	display: block;
	border: 0;
	padding: 0;
	margin: 0;
}

#colorbox, #cboxContent, #cboxLoadedContent {
	box-sizing: content-box;
	-moz-box-sizing: content-box;
	-webkit-box-sizing: content-box;
}

#cboxOverlay {
	background-color: RGBA(12,12,12,.7);
	opacity: 1 !important;
}

#colorbox {
	outline: 0;
}

#cboxContent {
	margin: 40px 50px 40px 50px;
	background: #f2f3f2;
}

.cboxIframe {
	background: #fff;
}

#cboxError {
	padding: 50px;
	border: 1px solid #ccc;
}

#cboxLoadedContent {
	//background: #909090;
	background: #f2f3f2;
}

#cboxTitle {
	margin: 0;
	position: absolute;
	bottom: -35px;
	left: 0;
	background: #f2f3f2;
	color: #444444;
	width: 100%;
	font-size: 14px;
	padding: 10px;
	min-height: 50px;
	display: flex!important;
	align-items: center;
	justify-content: center;
}

#cboxCurrent {
	position: absolute;
	top: -20px;
	right: 0px;
	color: #ccc;
	display: none;
}

#cboxLoadingGraphic {
	background: url(/assets/img//loading.gif) no-repeat center center;
}

/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
#cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {
	border: 0;
	padding: 0;
	margin: 0;
	overflow: visible;
	width: auto;
	background: none;
}

/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
#cboxPrevious:active,
#cboxNext:active,
#cboxSlideshow:active,
#cboxClose:active {
	outline: 0;
}

#cboxSlideshow {
	position: absolute;
	top: -20px;
	right: 90px;
	color: #fff;
}

#cboxPrevious {
	position: absolute;
	top: 50%;
	left: -45px;
	margin-top: -18px;
	width: 25px;
	outline: none;
	display: none;
	color: #f2f3f2;
	font-size: 36px;
	&:hover {
		color: #989897;
	}
}

#cboxNext {
	position: absolute;
	top: 50%;
	right: -45px;
	margin-top: -18px;
	width: 25px;
	outline: none;
	display: none;
	color: #f2f3f2;
	font-size: 36px;
	&:hover {
		color: #989897;
	}
}

#cboxClose {
	position: absolute;
	top: -40px;
	left: auto;
	right: -35px;
	bottom: auto;
	display: block;
	width: 28px;
	height: 36px;
	line-height: 1;
	margin: 0 auto;
	outline: 0;
	opacity: 1;
	color: #f2f3f2;
	font-size: 36px;
	&:hover {
		opacity: 1;
		color: #989897;
	}
}
