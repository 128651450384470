* {
	background: none;
}

/* LINKS */
a {
	color: $gray-dark;
	text-decoration: underline;
	&:hover,
	&:focus {
		color: $red-dirty;
		text-decoration: none;
		outline: none;
	}
}

.link-more {
	display: inline-block;
	text-decoration: none;
	padding: 0 0 5px 0;
	border-bottom: 1px solid $black;
	text-transform: uppercase;
	font-weight: bold;
	&:hover {
		border-bottom: 1px solid $red-dirty;
		color: $red-dirty;
	}
}

.link-more_white-hover {
	&:hover {
		border-bottom: 1px solid $white;
		color: $white;
	}
}

.link-more_white {
	color: $white;
	border-bottom: 1px solid $white;
	&:hover {
		border-bottom: 1px solid $gray-dark;
		color: $gray-dark;
	}
}

/* mobile */
.menu-btn {
	cursor: pointer;
	font-size: 18px;
	height: 34px;
	padding: 5px 0px;
	z-index: 1000;
	color: $red-dirty;
	&-title {
		color: $black;
		cursor: pointer;
		position: absolute;
		top: 5px;
		left: 50px;
	}
	&.navbar-toggle {
		margin: 5px 0;
		float: none;
	}
	& span {
		transition: opacity 0.3s ease-in-out;
		background: $red-dirty;
		display: block;
		width: 30px;
		height: 4px;
	}
	& .top,
	& .meat {
		margin-bottom: 4px;
	}

	&.closed {
		opacity: 1;
		text-shadow: none;
	}
	&.closed .top {
		opacity: 0;
	}
	&.closed .meat {
		opacity: 1;
	}
	&.closed .bottom {
		opacity: 0;
	}
}

/* FORMS */
.btn {
	display: inline-block;
	text-decoration: none;
	font-style: normal;
	border: 1px solid $red-dirty;
	padding: 12px 20px;
	font-size: 1em;
	color: $red-dirty;
	font-weight: 400;
	line-height: 1;
	text-align: center;
	cursor: pointer;
	border-radius: 0;
	background: none;
	//transition: all 0.3s ease-out;
	box-shadow: none;
	&:hover,
	&:focus {
		color: $white;
		background: $red-dirty;
		border-color: $red-dirty;
	}
	&:active {
		border-color: $red-dirty;
		transition: all 0.2s ease-out;
	}
}

.btn-default {
	background: $red;
	border: 1px solid $red;
	padding: 12px 20px;
	font-size: 1.75em;
	color: $white;
	font-weight: 400;
	line-height: 1;
	&:hover,
	&:focus {
		color: $white;
		background: $red-dirty;
		border-color: $red-dirty;
	}
	&:active,
	&:active:focus {
		color: $white;
		background: $red-dirty;
		border-color: $red-dirty;
		transition: all 0.2s ease-out;
	}
}
.btn-default:disabled {
	background: $gray;
}

.form-group {
	margin-bottom: 24px;
}

.form-control {
	&:focus {
		border-color: $red-dirty;
		box-shadow: none;
	}
}
.input-group-lg>.input-group-btn>select.btn,
.input-group-lg>select.form-control,
.input-group-lg>select.input-group-addon,
select.input-lg {
	height: 56px;
	line-height: 56px;
	padding: 15px 22px;
	& option {
		padding: 15px 22px;
	}
}

.input-group-lg>.form-control,
.input-group-lg>.input-group-addon,
.input-group-lg>.input-group-btn>.btn,
.input-lg {
	border-radius: 0;
	height: 56px;
	padding: 15px 22px;
	font-size: 16px;
}

.errorSummary,
.successResult {
	font-size: 14px;
	line-height: 1.8;
	margin-top: 20px;
}
.errorSummary {
	color: #e84c3d;
}
.successResult {
	color: #00cc00;
}

::-webkit-input-placeholder {
	color:#b4b4b4;
	font-style: italic;
	font-weight: 300;
}
::-moz-placeholder {
	color:#b4b4b4;
	font-style: italic;
	font-weight: 300;
}/* Firefox 19+ */
:-moz-placeholder {
	color:#b4b4b4;
	font-style: italic;
	font-weight: 300;
}/* Firefox 18- */
:-ms-input-placeholder {
	color:#b4b4b4;
	font-style: italic;
	font-weight: 300;
}

/* PAINATIONS */
.paginations {
	display: inline-block;
	margin: 0;
	&__item {
		display: inline-block;
		color: #aaa;
		text-align: center;
		vertical-align: middle;
		text-decoration: none;
		font-size: 16px;
		font-weight: 600;
		width: 28px;
		height: 28px;
		line-height: 28px;
		& .icon {
			font-size: 20px;
			font-weight: 100;
			line-height: 24px;
			vertical-align: top;
		}
		&:hover {
			color: #ffcb05;
			text-decoration: none;
		}
		&.active,
		&.selected {
			& .paginations-link {
				color: $white !important;
				background: $red-dirty;
				border-color: $red-dirty;
			}
		}
	}
	&-link {
		border-radius: 0 !important;
		display: inline-block;
		color: $red-dirty !important;
		background: none;
		border: none;
		text-decoration: none;
		font-weight: 400;
		&:hover {
			color: #ffcb05;
		}
		.active &,
		.selected & {
			color: #000;
		}
	}
}

/* Wayra */
.button--wayra {
	overflow: hidden;
	width: 245px;
	-webkit-transition: border-color 0.3s, color 0.3s;
	transition: border-color 0.3s, color 0.3s;
	-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
	transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.button--wayra::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 150%;
	height: 100%;
	background: #37474f;
	z-index: -1;
	-webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0);
	transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0);
	-webkit-transform-origin: 0% 100%;
	transform-origin: 0% 100%;
	-webkit-transition: -webkit-transform 0.3s, opacity 0.3s, background-color 0.3s;
	transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
}
.button--wayra:hover {
	color: #fff;
	border-color: #3f51b5;
}
.button--wayra.button--inverted:hover {
	color: #3f51b5;
	border-color: #fff;
}
.button--wayra:hover::before {
	opacity: 1;
	background-color: #3f51b5;
	-webkit-transform: rotate3d(0, 0, 1, 0deg);
	transform: rotate3d(0, 0, 1, 0deg);
	-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
	transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.button--wayra.button--inverted:hover::before {
	background-color: #fff;
}