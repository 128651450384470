/* HEADER */
.header {
	background: $white;
	position: relative;
	box-shadow: 0 4px 2px 0px rgba(0, 0, 0, .25);
	&__logo {
		display: block;
		float: left;
		vertical-align: middle;
		padding: 32px 0;
		text-decoration: none;
	}
	&__navigation {
		float: left;
		display: block;
		padding: 0 30px;
	}
	&__navigation-mobile {
		float: right !important;
		margin-left: 20px;
		margin-top: 27px;
	}
	&__phone {
		float: right;
		font-weight: bold;
		line-height: 105px;
	}
	&__callback {
		float: right;
		margin-left: 20px;
		padding: 32px 0;
	}
	&__callback-link {
		
	}
}
.top-navigation {
	$this: &;
	display: block;
	padding: 0;
	margin: 0;
	&__item {
		display: block;
		float: left;
		&.active {
			#{$this}__link {
				color: $red-dirty;
			}
		}
	}
	&__link {
		display: block;
		text-transform: uppercase;
		padding: 46px 0 42px;
		margin: 0 18px;
		color: $gray-dark;
		text-decoration: none;
		font-size: 0.875em;
		&:hover {
			color: $red-dirty;
			padding: 46px 0 38px;
			border-bottom: solid 4px $red-dirty;
		}
	}
	&_sub {
		padding: 0;
		margin-top: 10px;
		border: none;
		border-radius: 0;
		background-color: rgba(104,104,104,.85);
		&:before {
			content: ''; 
			position: absolute;
			left: 45px;
			top: -20px;
			margin-left: -10px;
			border: 10px solid transparent;
			border-bottom: 10px solid rgba(104,104,104,.85);
		}
	}
	&__item_sub {
		& + #{$this}__item_sub {
			border-top: 1px solid rgba(255,255,255,.1);
		}
	}
	&__link_sub {
		position: relative;
		padding: 15px 45px 15px 30px!important;
		text-decoration: none !important;
		color: $white-dirty-dark !important;
		background-color: transparent !important;
		&:hover {
			background: transparent !important;
			color: $white !important;
		}
	}
	&__link-icon_sub {
		position: absolute;
		top: 0;
		right: 0;
		font-size: 2em;
		line-height: 1em;
		padding: 10px!important;
	}
}

/* MAIN */
.page-title {
	font-weight: 300;
	font-size: 3em;
	margin: 0 0 60px 0;
	text-transform: uppercase;
	text-align: center;
	&_compact {
		margin-bottom: 20px;
	}
}
.page-title-caption {
	font-size: 0.875em;
	margin-bottom: 60px;
}

.section-main-photo {
	$this: &;
	&__about {
		background: $red;
		height: 256px;
		color: $white;
		position: absolute;
		top: -128px;
		z-index:2;
		&:before {
			display: block;
			content: "";
			position: absolute;
			z-index: 1;
			box-shadow: 0 0 50px rgba(0,0,0,0.25);
			top: 50%;
			bottom: 0;
			left: 50px;
			right: 50px;
			border-radius: 100px / 50px;
		}
		&.opened {
			height: auto;
			#{$this}__about-content {
				height: auto;
			}
		}
	}
	&__about-content {
		background: $red;
		height: 170px;
		padding: 30px 30px 0 30px;
		overflow: hidden;
		position: relative;
		z-index: 3;
	}
	&__about-more {
		background: $red;
		padding: 25px;
		position: relative;
		z-index: 3;
	}
}

.section-main-services {
	$this: &;
	padding: 200px 0 60px 0;
	&__list {
		display: block;
		padding: 0;
		margin: 0;
		text-align: center;
	}
	&__item {
		padding: 35px;
		margin: 0;
		width: 20%;
		display: inline-block;
		text-align: center;
		vertical-align: top;
	}
	&__link {
		text-decoration: none;
		&:hover {
			#{$this}__sign {
				background: $red-dirty;
				border: 4px solid $red-dirty;
			}
			#{$this}__icon {
				color: $white;
			}
			#{$this}__caption {
				color: $red-dirty;
			}
		}
	}
	&__sign {
		display: block;
		margin: 0 auto;
		width: 90px;
		height: 90px;
		border: 4px solid $red;
		border-radius: 50%;
		text-align: center;
	}
	&__icon {
		line-height:82px;
		font-size: 50px;
		color: $red;
	}
	&__caption {
		display: block;
		margin-top: 30px;
		font-size: 0.875em;
		text-transform: uppercase;
		font-weight: bold;
	}
}

.section-main-commands {
	background: $red;
	padding: 60px 0;
	color: $white;
}

.slider-commands {
	&__item {
		margin: 0 70px;
	}
	&__item-title {
		margin-bottom: 20px;
		text-transform: uppercase;
		font-weight: bold;
	}
	&__item-content {
		font-size: 0.875em;
	}
	&__button {
		color: $white;
		background-image: none !important;
		font-size: 2.5em;
		&:hover {
			color: $red-dirty;
		}
	}
	&__button-prev {
		left: -120px;
	}
	&__button-next {
		right: -120px;
	}
	&__button-prev_nearby {
		left: -22px;
	}
	&__button-next_nearby {
		right: -22px;
	}
	&__icon-left {
		color: $black;
		&:hover {
			color: $red-dirty;
		}
	}
	&__icon-right {
		color: $black;
		&:hover {
			color: $red-dirty;
		}
	}
}

.section-main-portfolio {
	overflow: hidden;
	background: $white-dirty;
	$this: &;
	&__item {
		position: relative;
		display: inline-block;
		width: 33.33%;
		&:hover {
			#{$this}__caption {
				opacity: 1;
				z-index: 3;
			}
		}
	}
	&__img {
		position: relative;
		z-index: 2;
		width: 100%;
	}
	&__caption {
		position: absolute;
		left: 0;
		top: 0;
		display: inline-block;
		width: 100%;
		height: 100%;
		padding: 15%;
		background: $red-dirty;
		color: $white;
		text-align: center;
		opacity: 0;
		z-index: 1;
	}
	&__caption-content {
		display: block;
		border: 10px solid $white;
		overflow: hidden;
		height: 100%;
		width: 100%;
	}
	&__caption-date {
		width: 100%;
		height: 25%;
		display: table;
		& span {
			display: table-cell;
			width: 100%;
			vertical-align: middle;
		}
	}
	&__caption-title {
		text-transform: uppercase;
		font-size: 2em;
		font-weight: bold;
		line-height: 1em;
		width: 100%;
		height: 50%;
		display: table;
		& span {
			padding: 0 20px;
			display: table-cell;
			width: 100%;
			vertical-align: middle;
		}
	}
	&__caption-customer {
		width: 100%;
		height: 25%;
		display: table;
		& span {
			display: table-cell;
			width: 100%;
			vertical-align: middle;
		}
	}
	&__get-more {
		text-align: center;
		margin: 50px 50px 0 50px;
	}
}

.section-main-clients {
	overflow: hidden;
	padding: 60px 0;
	background: $white-dirty;
	&__item {
		text-align: center;
	}
}

.section-main-news {
	overflow: hidden;
	padding: 60px 0;
	background: $white-dirty-light;
	&__list {
		display: block;
	}
	&__get-more {
		text-align: center;
		margin: 0px;
	}
}

.section-news {
	overflow: hidden;
	padding: 60px 0;
	background: $white-dirty-light;
	&__list {
		display: block;
	}
}

.news {
	&__item {
		background: $white;
		max-height: 245px;
		margin-bottom: 50px;
		position: relative;
		z-index: 2;
		&_opened {
			max-height: none;
		}
		&:before {
			display: block;
			content: "";
			position: absolute;
			z-index: 1;
			box-shadow: 0 0 50px rgba(0,0,0,0.25);
			top: 50%;
			bottom: 0;
			left: 50px;
			right: 50px;
			border-radius: 100px / 50px;
		}
	}
	&__block-img {
		background: $white;
		display: block;
		width: 245px;
		height: 245px;
		float: left;
		position: relative;
		z-index: 3;
	}
	&__block-info {
		background: $white;
		display: block;
		position:relative;
		max-height: 245px;
		z-index: 2;
		padding: 40px 60px 40px 305px;
		&_opened {
			max-height: none;
			min-height: 245px;
		}
	}
	&__date {
		font-weight: bold;
		margin-bottom: 25px;
	}
	&__content {
		margin-bottom: 25px;
		height: 80px;
		overflow: hidden;
		&_opened {
			height: auto;
		}
	}
	&__more {
		margin-bottom: 0;
	}
}

.section-main-description {
	background: $red;
	color: $white;
	&__text {
		padding: 60px 0;
	}
}

.section-map {
	position: relative;
	&__container {
		position: relative;
		height: 0;
	}
	&__row {
		position: relative;
		height: 0;
	}
	&__layer-map {
		position: relative;
		z-index: 1;
	}
	&__layer-form {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 0;
		z-index: 2;
	}
	&__form {
		background: #fff;
		margin-top: 20px;
		padding-left: 40px;
		padding-right: 40px;
		padding-top: 20px;
		padding-bottom: 20px;
	}
	&__form-title {
		font-size: 1.250em;
		background: #fff;
		margin-top: 20px;
		margin-bottom: 20px;
		text-align: center;
	}
}

.section-page {
	overflow: hidden;
	padding: 60px 0;
	background: $white-dirty-light;
}

.section-contacts {
	&__item {
		background: $white;
		margin-bottom: 50px;
		position: relative;
		z-index: 2;
		&:before {
			display: block;
			content: "";
			position: absolute;
			z-index: 1;
			box-shadow: 0 0 50px rgba(0,0,0,0.25);
			top: 50%;
			bottom: 0;
			left: 50px;
			right: 50px;
			border-radius: 100px / 50px;
		}
	}
	&__block-img {
		background: $white;
		display: block;
		float: left;
		position: relative;
		z-index: 3;
	}
	&__block-info {
		background: $white;
		display: block;
		position:relative;
		z-index: 2;
		min-height: 332px;
		padding: 40px 60px 40px 305px;
	}
	&__block-name {
		color: $red-dirty;
		font-size: 1.5em;
		font-weight: bold;
	}
}

.section-services {
	$this: &;
	&__item {
		margin-bottom: 50px;
		position: relative;
		&.opened {
			#{$this}__card-content {
				display: block;
			}
		}
	}
	&__card {
		text-decoration: none;
		background: $white;
		position: relative;
		z-index: 2;
		&:before {
			display: block;
			content: "";
			position: absolute;
			z-index: -1;
			box-shadow: 0 0 50px rgba(0,0,0,0.25);
			top: 50%;
			bottom: 0;
			left: 50px;
			right: 50px;
			border-radius: 100px / 50px;
		}
	}
	&__card-inner {
		display: block;
		background: $white;
		z-index: 3;
	}
	&__card-icon {
		font-size: 3em;
		margin-right: 60px;
		background: $red-dirty;
		color: $white;
		width: 140px;
		display: inline-block;
		padding: 40px 35px;
		text-align: center;
	}
	&__card-title {
		font-size: 1.875em;
		padding: 40px 0;
		text-transform: uppercase;
	}
	&__card-more-sign {
		display: inline-block;
		padding: 40px;
		float: right;
	}
	&__card-arrow {
		font-size: 3em;
	}
	&__card-content {
		display: none;
		& h3 {
			font-size: 1.875em;
			font-weight: normal;
			text-transform: uppercase;
			margin-bottom: 30px;
		}
		& h4 {
			font-size: 1em;
			font-weight: strong;
			margin-bottom: 20px;
		}
		& p {
			font-size: 0.875em;
			margin-bottom: 20px;
		}
		& ul {
			padding-left: 25px;
		}
		& li {
			font-size: 0.875em;
			margin-bottom: 20px;
			list-style-type: none;
			position: relative;
			&:before {
				color: $gray;
				content: "\2015";
				position: absolute;
				left: -25px;
				top: 0;
			}
		}
		& table {
			width: 100%;
			& tr {
				& th {
					border: 1px solid $white;
					font-size: 1.5em;
					padding: 10px 15px;
				}
				& td {
					border: 1px solid $white;
					padding: 10px 15px;
				}
			}
		}
		hr {
			margin-top: 40px;
			margin-bottom: 40px;
			border: 0;
			border-top: 1px solid $gray-light;
		}
	}
	&__card-content-transparent {
		margin-top: 50px;
	}
	&__card-content-white {
		background: $white;
		margin-top: 50px;
		padding: 40px;
	}
	&__card-content-red-pane {
		background: $red;
		margin: 40px -70px 0;
		padding: 30px;
		color: $white;
	}
}

.section-projects {
	$this: &;
	&__item {
		display: block;
		background: $white;
		margin-bottom: 50px;
		text-decoration: none;
		position: relative;
		z-index: 2;
		&:hover {
			#{$this}__item-hover {
				opacity: 0.75;
			}
		}
		&:before {
			display: block;
			content: "";
			position: absolute;
			z-index: -1;
			box-shadow: 0 0 50px rgba(0,0,0,0.25);
			top: 50%;
			bottom: 0;
			left: 50px;
			right: 50px;
			border-radius: 100px / 50px;
		}
	}
	&__item-inner {
		height: auto;
		display: block;
		position: relative;
		background: $white;
		z-index: 2;
	}
	&__item-hover {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 3;
		background: $red-dirty;
		color: $white;
		opacity: 0;
		text-align: center;
	}
	&__item-hover-sign {
		display: block;
		width: 100%;
		height: 72px;
		position: absolute;
		top: 50%;
		margin-top: -36px;
	}
	&__item-hover-icon {
		font-size: 2em;
	}
	&__item-hover-caption {
		font-size: 1.5em;
	}
	&__info-block {
		display: block;
		position: absolute;
		width: 50%;
		height: 100%;
		overflow: hidden;
	}
	&__info-block-table {
		display: table;
		width: 100%;
		height: 100%;
	}
	&__info-block-td {
		display: table-cell;
		width: 100%;
		height: 100%;
		text-align: center;
		vertical-align: middle;
		padding: 40px;
	}
	&__img-block {
		display: block;
		width: 50%;
		margin-left: 50%;
	}
	&__date {
		color: $gray-dirty;
	}
	&__title {
		font-size: 2.5em;
		line-height: 1;
		color: $red;
		display: inline-block;
		margin: 20px 0;
	}
	&__description {
		display: inline-block;
		margin: 30px 50px 0;
		color: $gray-dirty;
	}
	&__categories {
		padding-bottom: 20px;
		text-align: center;
	}
	&__category {
		display: inline-block;
		margin: 10px;
	}
	&__category_active {
		color: $red-dirty;
	}
}

.section-project {
	$this: &;
	&__prev-next {
		margin-bottom: 50px;
	}
	&__prev {
		display: block;
		text-decoration: none;
		text-transform: uppercase;
		font-weight: bold;
		text-align: left;
	}
	&__next {
		display: block;
		text-decoration: none;
		text-transform: uppercase;
		font-weight: bold;
		text-align: right;
	}
	&__icon-prev {
		font-size: 2em;
		position: relative;
		margin-right: 10px;
		top: 3px;
	}
	&__icon-next {
		font-size: 2em;
		position: relative;
		margin-left: 10px;
		top: 3px;
	}
	&__item {
		display: block;
		background: $white;
		margin-bottom: 50px;
		text-decoration: none;
		position: relative;
		z-index: 2;
		&:before {
			display: block;
			content: "";
			position: absolute;
			z-index: -1;
			box-shadow: 0 0 50px rgba(0,0,0,0.25);
			top: 50%;
			bottom: 0;
			left: 50px;
			right: 50px;
			border-radius: 100px / 50px;
		}
	}
	&__item-inner {
		display: block;
		position: relative;
		background: $white;
		z-index: 2;
		padding: 40px 100px;
	}
	&__item-date {
		color: $gray-dirty;
		margin-bottom: 5px;
	}
	&__item-title {
		margin-top: 0;
		margin-bottom: 5px;
		font-size: 3.438em
	}
	&__item-customer {
		color: $gray-dirty;
		margin-top: 0;
		margin-bottom: 30px;
	}
	&__item-btn {
		width: 100%;
		font-size: 3em;
		border: none;
		padding: 50px;
		margin-bottom: 5px;
	}
	&__video {
		margin-bottom: 50px;
		display: block;
		background: $white;
		position: relative;
		z-index: 2;
		&:before {
			display: block;
			content: "";
			position: absolute;
			z-index: -1;
			box-shadow: 0 0 50px rgba(0,0,0,0.25);
			top: 50%;
			bottom: 0;
			left: 50px;
			right: 50px;
			border-radius: 100px / 50px;
		}
	}
	&__video-inner {
		display: block;
		position: relative;
		background: $white;
		z-index: 2;
		& iframe {
			display: block;
		}
	}
	&__gallery {
		margin-bottom: 50px;
		display: block;
		background: $white;
		position: relative;
		z-index: 2;
		&:before {
			display: block;
			content: "";
			position: absolute;
			z-index: -1;
			box-shadow: 0 0 50px rgba(0,0,0,0.25);
			top: 50%;
			bottom: 0;
			left: 50px;
			right: 50px;
			border-radius: 100px / 50px;
		}
	}
	&__gallery-inner {
		display: block;
		position: relative;
		background: $white;
		z-index: 2;
		& iframe {
			display: block;
		}
	}
}

.section-order {
	&__form {
		background: $white;
		padding: 45px 100px;
	}
	&__form-group {
		margin-bottom: 40px;
	}
	&__form-title {
		text-align: center;
		font-size: 1em;
		margin-bottom: 20px;
	}
	&__form-section {
		margin-bottom: 50px;
	}
}

/* FOOTER */
.footer  {
	background: $white;
	padding: 40px 0;
	position: relative;
	&__container {
		position: relative;
	}
	&__contacts {
		width: 50%;
		float: left;
		color: $gray-dark;
		& p {
			margin: 0 0 19px 0;
		}
	}
	&__socials {
		width: 50%;
		float: left;
		text-align: right;
	}
	&__social-link {
		font-size: 1.5em;
		color: $gray;
		margin-left: 10px;
		text-decoration: none;
		&_vkontakte {
			&:hover {
				color: #537599;
			}
		}
		&_instagram {
			&:hover {
				color: #125688;
			}
		}
		&_twitter {
			&:hover {
				color: #55acee;
			}
		}
		&_gplus {
			&:hover {
				color: #db4437;
			}
		}
	}
	&__thekilo {
		position: absolute;
		bottom: 7px;
		right: 15px;
	}
	&__copy {
		color: $gray;
		position: absolute;
		bottom: -25px;
		left: 15px;
		font-size: 0.875em;
	}
}
.footer-navigation {
	padding: 0;
	margin: 0;
	&__col {
		display: block;
		width: 50%;
		margin: 0;
		padding: 0;
		float: left;
	}
	&__item {
		display: block;
	}
	&__link {
		display: inline-block;
		text-transform: uppercase;
		padding: 0 0 5px 0;
		margin: 0 0 15px 0;
		color: $gray-dark;
		text-decoration: none;
		font-size: 0.875em;
		&:hover {
			padding: 0 0 4px 0;
			border-bottom: 1px solid $red-dirty;
			color: $red-dirty;
		}
	}
}

/* THE KILO */
.thekilo {
	$this: &;
	display: inline-block;
	height: 50px;
	color: $gray;
	padding: 1px;
	text-align: center;
	text-decoration: none;
	&__logo {
		width: 40px;
		transform: translateY(5px);
		transition: all 0.3s ease-out;
		&-path {
			transition: all 0.3s ease-out;
			fill: $gray;
		}
	}
	&__text {
		display: block;
		font-size: 10px;
		font-weight: 400;
		margin-top: -4px;
		transform: translateY(10px);
		opacity: 0;
		transition: all 0.3s ease-out;
	}
	&:hover,
	&:focus {
		color: $gray-dark;
		#{$this}__logo {
			transform: translateY(0);
			&-path {
				fill: $gray-dark;
			}
		}
		#{$this}__text {
			transform: translateY(0);
			opacity: 1;
		}
	}
}

/* ERROR */
.body-error {
	background: $red;
	color: $white;
	& .page__title {
		margin-top: 10%;
		font-size: 10em;
		color: rgba(168,32,33, 0.8);
		text-shadow: 1px 4px 6px #aa2021, 0 0 0 #000, 1px 4px 6px $red;
	}
	& p {
		margin-bottom: 30px;
	}
}