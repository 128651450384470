$white: #fff;
$white-dirty: #f3f3f3;
$white-dirty-light: #f8f8f8;
$white-dirty-dark: #e3e3e3;
$black: #000;
$gray-dark: #3a3a3a;
$gray-dirty: #555555;
$gray: #aeaeae;
$gray-light: #dadada;
$red-dirty: #b32223;
$red: #ec4141;

$yellow: #e2be4f;
$yellow-light: #ffdb6c;
$yellow-dark: #c69b19;
$yellow-sdark: #bb9215;
$brown: #966313;
$orange: #d06218;
$orange-light: #f47724;
$orange-slight: #fd8b3f;
$blue: #386272;
$blue-light: #6f95a4;
$blue-dark: #34495e;
$cyan: #64aea4;
$cyan-light: #82c7be;
$cyan-slight: #96e3d9;
$cyan-sslight: #ccede9;
$cyan-dark: #1ccca9;
$cyan-sdrak: #16a085;
$gray-light: #c3c4c4;
$gray-slight: #d7d7d7;
$gray-sdrak: #3d3d3d;

$Roboto: 'Roboto', sans-serif;

html {
	height: 100%;
}

body {
	width: 100%;
	height: 100%;
	margin: 0;
	background: #fff;
	font-family: $Roboto;
	font-size: 16px;
	line-height: 1.3;
	height: 100%;
	color: #1a1a1a;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $Roboto;
}

img {
	max-width: 100%;
}

.main-container {
	overflow: hidden;
}

.rel {
	position: relative;
}

.hidden {
	display: none !important;
}

.vertical-center {
	display: block;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}